<template>
    <PageError :status-code="error.statusCode" />
</template>

<script setup lang="ts">
import type { NuxtError } from '#app';
import { faviconHead } from '~/constants/favicons';

defineProps<{
    error: NuxtError
}>();

useHead(faviconHead);
</script>

<style lang="less"></style>
